export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["Auto"] = "auto";
    ButtonSize["Full"] = "full";
})(ButtonSize || (ButtonSize = {}));
export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["Primary"] = "primary";
    ButtonVariant["Empty"] = "empty";
    ButtonVariant["BlueAccent"] = "blue";
    ButtonVariant["Highlight"] = "highlight";
    ButtonVariant["PurpleHighlight"] = "PurpleHighlight";
    ButtonVariant["RedHighlight"] = "RedHighlight";
    ButtonVariant["GrayAccent"] = "gray";
    ButtonVariant["Outline"] = "outline";
})(ButtonVariant || (ButtonVariant = {}));
