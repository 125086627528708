import React, { useContext, useMemo } from 'react';
import { SignatureFormContext } from './SignatureFormContext';
import EditSignatureForm from './Forms/edit-signature-form';
import NewSignatureForm from './Forms/new-signature-form';
function SignatureForm() {
    const { signature } = useContext(SignatureFormContext);
    const form = useMemo(() => {
        if (signature === undefined)
            return null;
        if ('id' in signature)
            return React.createElement(EditSignatureForm, null);
        return React.createElement(NewSignatureForm, null);
    }, [signature]);
    return form;
}
export default SignatureForm;
