import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import { Button } from '@components/Button';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ButtonVariant } from '../Button/types.ts';

function ConfirmDialog({
  title,
  description,
  onConfirm,
  onCancel,
  loading,
  noLabel = translate('cancel', { namespace: 'features.commons' }),
  yesLabel = translate('ok', { namespace: 'features.commons' }),
  confirmButtonVariant = ButtonVariant.PurpleHighlight,
  cancelButtonVariant = ButtonVariant.GrayAccent
}) {
  return (
    <Dialog title={title} className="qmb-dialog" onClose={onCancel}>
      <p>{description}</p>
      <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
        <Button variant={cancelButtonVariant} onClick={onCancel}>
          {noLabel}
        </Button>
        <Button variant={confirmButtonVariant} onClick={onConfirm} disabled={loading}>
          {yesLabel}
        </Button>
      </div>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonVariant: PropTypes.string,
  cancelButtonVariant: PropTypes.string,
  loading: PropTypes.bool,
  noLabel: PropTypes.string,
  yesLabel: PropTypes.string
};

ConfirmDialog.defaultProps = {
  loading: false,
  confirmButtonVariant: ButtonVariant.PurpleHighlight,
  cancelButtonVariant: ButtonVariant.Empty,
  noLabel: translate('cancel', { namespace: 'features.commons' }),
  yesLabel: translate('ok', { namespace: 'features.commons' })
};

export default ConfirmDialog;
