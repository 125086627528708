var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { translate } from '@utils/i18n';
import toast from 'react-hot-toast';
import { updateDocument } from '../services/DocumentService';
import { ToastDuration, namespace } from '../constants';
import { AIDeficiencyReviewStatus, PDFViewerStatus, ReviewStatus } from '../types';
export default function useDeficienciesAI({ inspectionId, parentDocument, onFinish }) {
    var _a;
    const queryClient = useQueryClient();
    const deficiencies = (_a = parentDocument === null || parentDocument === void 0 ? void 0 : parentDocument.deficiencies) !== null && _a !== void 0 ? _a : [];
    const [highlightInfo, setHighlightInfo] = useState(null);
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const [openNewDeficiency, setOpenNewDeficiency] = useState(false);
    const [bulkUpdateLoading, setBulkUpdateLoading] = useState(null);
    const [requestAddToInspectionModalOpen, setRequestAddToInspectionModalOpen] = useState(false);
    const [pdfViewerStatus, setPdfViewerStatus] = useState(PDFViewerStatus.Normal);
    const [deficiencyDetail, setDeficiencyDetail] = useState(null);
    const [defIdLoading, setDefIdLoading] = useState(null);
    const _b = useMutation({
        mutationFn: ({ documentUpdated }) => updateDocument({
            inspectionId,
            newDocument: documentUpdated
        }),
        onError: () => {
            toast.error('There was an error updating the document', {
                position: 'bottom-right',
                removeDelay: ToastDuration
            });
        },
        onSuccess: (newDocument) => {
            var _a;
            const currentDocumentsData = queryClient.getQueryData(['documents', inspectionId]);
            queryClient.setQueryData(['documents', inspectionId], currentDocumentsData === null || currentDocumentsData === void 0 ? void 0 : currentDocumentsData.map((doc) => (doc.id === (newDocument === null || newDocument === void 0 ? void 0 : newDocument.id) ? newDocument : doc)));
            if ((newDocument === null || newDocument === void 0 ? void 0 : newDocument.status) === ReviewStatus.Completed) {
                setRequestAddToInspectionModalOpen(false);
                onFinish(newDocument);
                return;
            }
            const defUpdated = (_a = newDocument === null || newDocument === void 0 ? void 0 : newDocument.deficiencies) === null || _a === void 0 ? void 0 : _a.find((def) => def.deficiency_uid === (deficiencyDetail === null || deficiencyDetail === void 0 ? void 0 : deficiencyDetail.deficiency_uid));
            setDeficiencyDetail(defUpdated || null);
        }
    }), { isLoading: isCompletingReview } = _b, changeDocumentMutation = __rest(_b, ["isLoading"]);
    const pendingDeficiencies = useMemo(() => {
        return (deficiencies || []).filter((deficiency) => (deficiency.reviewStatus === AIDeficiencyReviewStatus.Incorrect ||
            deficiency.reviewStatus === AIDeficiencyReviewStatus.WaitingReview) &&
            !deficiency.isManuallyAdded).length;
    }, [deficiencies]);
    const isEmpty = useMemo(() => {
        return (deficiencies.length === 0 || deficiencies.every((def) => def.reviewStatus === AIDeficiencyReviewStatus.Removed));
    }, [deficiencies]);
    useEffect(() => {
        if (!parentDocument) {
            setPdfViewerStatus(PDFViewerStatus.Normal);
        }
    }, [parentDocument]);
    const onChangeDeficiency = (newDeficiency) => __awaiter(this, void 0, void 0, function* () {
        if (!(parentDocument === null || parentDocument === void 0 ? void 0 : parentDocument.status))
            return;
        const documentUpdate = Object.assign(Object.assign({}, parentDocument), { deficiencies: newDeficiency.deficiency_uid
                ? deficiencies.map((def) => (def.deficiency_uid === newDeficiency.deficiency_uid ? newDeficiency : def))
                : [...deficiencies, Object.assign(Object.assign({}, newDeficiency), { deficiency_uid: `missing${deficiencies.length + 1}` })], status: ReviewStatus.InProgress });
        const currentDocumentsData = queryClient.getQueryData(['documents', inspectionId]);
        queryClient.setQueryData(['documents', inspectionId], currentDocumentsData === null || currentDocumentsData === void 0 ? void 0 : currentDocumentsData.map((doc) => (doc.id === (documentUpdate === null || documentUpdate === void 0 ? void 0 : documentUpdate.id) ? documentUpdate : doc)));
        setDefIdLoading(newDeficiency.deficiency_uid);
        try {
            yield changeDocumentMutation.mutateAsync({
                documentUpdated: documentUpdate
            });
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setDefIdLoading(null);
        }
    });
    const onAddToInspection = () => __awaiter(this, void 0, void 0, function* () {
        if (!(parentDocument === null || parentDocument === void 0 ? void 0 : parentDocument.status))
            return;
        yield changeDocumentMutation.mutateAsync({
            documentUpdated: Object.assign(Object.assign({}, parentDocument), { deficiencies, status: ReviewStatus.Completed })
        });
    });
    const onBulkUpdate = (status) => __awaiter(this, void 0, void 0, function* () {
        if (!(parentDocument === null || parentDocument === void 0 ? void 0 : parentDocument.status))
            return;
        setBulkUpdateLoading(status);
        yield changeDocumentMutation.mutateAsync({
            documentUpdated: Object.assign(Object.assign({}, parentDocument), { deficiencies: deficiencies.map((def) => (Object.assign(Object.assign({}, def), { reviewStatus: def.reviewStatus !== AIDeficiencyReviewStatus.Removed && !def.isManuallyAdded ? status : def.reviewStatus }))), status: ReviewStatus.InProgress })
        });
        setBulkUpdateLoading(null);
    });
    const onFinishReview = () => {
        if (!(parentDocument === null || parentDocument === void 0 ? void 0 : parentDocument.status))
            return;
        void changeDocumentMutation.mutateAsync({
            documentUpdated: Object.assign(Object.assign({}, parentDocument), { deficiencies, status: ReviewStatus.Completed })
        });
    };
    const onAddFeedback = (text) => __awaiter(this, void 0, void 0, function* () {
        if (!(parentDocument === null || parentDocument === void 0 ? void 0 : parentDocument.status))
            return;
        yield changeDocumentMutation.mutateAsync({
            documentUpdated: Object.assign(Object.assign({}, parentDocument), { deficiencies, status: parentDocument.status, feedback: [...(parentDocument.feedback || []), text] })
        });
        setOpenFeedbackModal(false);
        toast.success(translate('feedback_success', { namespace }), {
            position: 'bottom-right',
            removeDelay: ToastDuration
        });
    });
    const onStartHighlightText = (params) => {
        setHighlightInfo(params);
    };
    const onStopHighlightText = () => {
        setHighlightInfo(null);
    };
    return {
        deficiencies,
        defIdLoading,
        pendingDeficiencies,
        pdfViewerStatus,
        deficiencyDetail,
        requestAddToInspectionModalOpen,
        bulkUpdateLoading,
        isEmpty,
        isCompletingReview,
        openFeedbackModal,
        onAddFeedback,
        setOpenFeedbackModal,
        onBulkUpdate,
        onAddToInspection,
        onFinishReview,
        setRequestAddToInspectionModalOpen,
        onChangeDeficiency,
        setDeficiencyDetail,
        setPdfViewerStatus,
        openNewDeficiency,
        setOpenNewDeficiency,
        onStartHighlightText,
        onStopHighlightText,
        highlightInfo
    };
}
