/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import Brushaway from '@components/Brushaway';
import InputTextArea, { StyleVariant } from '@components/InputTextArea/InputTextArea';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import TextInput from '@components/TextInput/TextInput';
import { Button, ButtonVariant } from '@components/Button';
import Select from '@components/Select/Select';
import { Controller, useForm } from 'react-hook-form';
import { translate } from '@utils/i18n';
import { AIDeficiencyReviewStatus, IPDeficiencyStatus } from '../types';
import DeficiencyInfo from './DeficiencyInfo';
import { namespace, StatusIcons } from '../constants';
import { snakeToCapitalize } from '../utils/utils';
import BrushawaySteps from './BrushawaySteps';
export default function DeficiencyDetail({ deficiency, title, onClose, onChangeDeficiency }) {
    var _a, _b;
    const isNewDeficiency = (deficiency === null || deficiency === void 0 ? void 0 : deficiency.deficiency_uid) === null;
    const [isEditing, setIsEditing] = React.useState(false);
    const [requestRemove, setRequestRemove] = React.useState(false);
    const [initialDeficiency, setInitialDeficiency] = React.useState(deficiency);
    const { control, register, reset, getValues } = useForm({
        defaultValues: {
            description: (deficiency === null || deficiency === void 0 ? void 0 : deficiency.description) || '',
            status: deficiency === null || deficiency === void 0 ? void 0 : deficiency.status,
            page_no: (deficiency === null || deficiency === void 0 ? void 0 : deficiency.page_no) ? Number(deficiency === null || deficiency === void 0 ? void 0 : deficiency.page_no) : null
        }
    });
    useEffect(() => {
        if (deficiency === null || deficiency === void 0 ? void 0 : deficiency.isManuallyAdded) {
            setIsEditing(true);
        }
    }, [deficiency]);
    useEffect(() => {
        reset({
            description: (deficiency === null || deficiency === void 0 ? void 0 : deficiency.description) || '',
            status: deficiency === null || deficiency === void 0 ? void 0 : deficiency.status,
            page_no: (deficiency === null || deficiency === void 0 ? void 0 : deficiency.page_no) ? Number(deficiency === null || deficiency === void 0 ? void 0 : deficiency.page_no) : null
        });
    }, [deficiency, reset]);
    useEffect(() => {
        setInitialDeficiency(deficiency);
        if (deficiency === null) {
            setIsEditing(false);
        }
    }, [deficiency]);
    const onChangeStatus = (newStatus) => {
        var _a;
        if (initialDeficiency === null)
            return;
        if (((deficiency === null || deficiency === void 0 ? void 0 : deficiency.reviewStatus) === AIDeficiencyReviewStatus.Incorrect &&
            newStatus === AIDeficiencyReviewStatus.Correct) ||
            (deficiency === null || deficiency === void 0 ? void 0 : deficiency.isManuallyAdded)) {
            if (getValues('page_no') === null && (deficiency === null || deficiency === void 0 ? void 0 : deficiency.isManuallyAdded)) {
                toast.error(translate('page_no_error', { namespace }));
                return;
            }
            if (getValues('description').trim() === '') {
                toast.error(translate('description_error', { namespace }));
                return;
            }
            setIsEditing(false);
        }
        if (newStatus === AIDeficiencyReviewStatus.Removed || (deficiency === null || deficiency === void 0 ? void 0 : deficiency.isManuallyAdded)) {
            onClose();
            setRequestRemove(false);
            setIsEditing(false);
        }
        void onChangeDeficiency(Object.assign(Object.assign({}, initialDeficiency), { reviewStatus: newStatus, description: getValues('description'), status: getValues('status'), page_no: ((_a = getValues('page_no')) === null || _a === void 0 ? void 0 : _a.toString()) || null }));
    };
    const statuses = Object.keys(IPDeficiencyStatus).map((key) => ({
        label: snakeToCapitalize(IPDeficiencyStatus[key]),
        value: IPDeficiencyStatus[key]
    }));
    const brushTitle = useMemo(() => {
        if (isNewDeficiency) {
            return translate('add_missing_deficiency', { namespace });
        }
        if (isEditing) {
            return translate('edit_deficiency', { namespace });
        }
        return title || '';
    }, [isNewDeficiency, isEditing, title]);
    return (React.createElement(Brushaway, { open: deficiency !== null, disableCloseOnClickOutside: true, title: brushTitle, isForm: false, wrapperContentClassName: "deficiencyDetailBrushawayWrapper", footerClassName: "deficiencyDetailBrushawayFooter", onClose: () => {
            setIsEditing(false);
            onClose();
        }, customFooter: (deficiency === null || deficiency === void 0 ? void 0 : deficiency.isManuallyAdded) ? (React.createElement(React.Fragment, null,
            deficiency.deficiency_uid && (React.createElement(Button, { style: { marginRight: 'auto' }, variant: ButtonVariant.RedHighlight, onClick: () => setRequestRemove(true) }, translate('delete', { namespace: 'features.commons' }))),
            React.createElement(Button, { onClick: onClose }, translate('close', { namespace: 'features.commons' })),
            React.createElement(Button, { variant: ButtonVariant.PurpleHighlight, onClick: () => onChangeStatus(AIDeficiencyReviewStatus.Correct) }, deficiency.deficiency_uid
                ? translate('update', { namespace: 'features.commons' })
                : translate('add_deficiency', { namespace })))) : (React.createElement(Button, { onClick: onClose }, translate('close', { namespace: 'features.commons' }))) },
        React.createElement("section", { className: "deficiencyDetailBrushaway" },
            !isEditing && deficiency && React.createElement(DeficiencyInfo, { deficiency: deficiency }),
            isEditing && (React.createElement("section", { className: "deficiencyDetailBrushawayForm" },
                React.createElement(Select, Object.assign({ required: true }, register('status'), { label: translate('status', { namespace }), id: "status", options: statuses })),
                React.createElement(Controller, { name: "page_no", control: control, rules: { pattern: /^[0-9]+$/, required: true }, render: ({ field }) => {
                        const handleChange = (e) => {
                            const { value } = e.target;
                            // Only allow positive integers
                            if (/^[0-9]*$/.test(value)) {
                                field.onChange(value);
                            }
                        };
                        return (React.createElement(TextInput, { id: "page_no", type: "number", step: "1", disabled: !(deficiency === null || deficiency === void 0 ? void 0 : deficiency.isManuallyAdded), readOnly: !(deficiency === null || deficiency === void 0 ? void 0 : deficiency.isManuallyAdded), "aria-readonly": !(deficiency === null || deficiency === void 0 ? void 0 : deficiency.isManuallyAdded), min: "1", value: field.value, name: field.name, onChange: handleChange, label: translate('page_number', { namespace }) }));
                    } }),
                React.createElement(Controller, { name: "description", control: control, render: ({ field }) => {
                        return (React.createElement(InputTextArea, { id: "description", value: field.value, name: field.name, onChange: field.onChange, buttonControlled: false, variant: StyleVariant.Light, label: translate('description', { namespace }) }));
                    } }))),
            React.createElement("section", { className: "deficiencyDetailBrushawayFooter" }, deficiency && !deficiency.isManuallyAdded && (React.createElement(React.Fragment, null,
                React.createElement(BrushawaySteps, { deficiency: deficiency, onChangeStatus: onChangeStatus, onStartEditing: () => setIsEditing(true), onStopEditing: () => setIsEditing(false), isConfirming: false }),
                React.createElement("div", { className: "deficiencyStatus" },
                    React.createElement("span", { className: "deficiencyStatusText" },
                        React.createElement("i", { className: StatusIcons[(_a = deficiency.reviewStatus) !== null && _a !== void 0 ? _a : ''] }),
                        snakeToCapitalize((_b = deficiency.reviewStatus) !== null && _b !== void 0 ? _b : '')))))),
            requestRemove && (React.createElement(ConfirmDialog, { title: "Remove Deficiency", description: "Do you want to remove this deficiency?", onConfirm: () => onChangeStatus(AIDeficiencyReviewStatus.Removed), onCancel: () => setRequestRemove(false) })))));
}
