import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { translate } from '@utils/i18n';
function VisitPriorityDropdown(params) {
    const namespace = 'components.visit_priority_dropdown';
    const selectedElement = params.visitPriorityList.find(option => option.value === params.selectedValue);
    return (React.createElement("div", { className: "qmb-select--x-full form__field" },
        React.createElement(DropDownList, { name: "priority", id: "priority", textField: "label", dataItemKey: "value", data: params.visitPriorityList, value: selectedElement, onChange: (e) => {
                params.onChange(e.value.value);
            }, disabled: params.disabled, size: "large" }),
        React.createElement("label", { className: "qmb-label" }, translate('priority', { namespace }))));
}
export default VisitPriorityDropdown;
