import { useEffect, useState } from 'react';
import apiClient from '@utils/api-client';
import { apiInternalVisitPrioritiesPath } from '../../routes';
const useGetVisitPriorities = (queryParams) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        if (!queryParams)
            return;
        setLoading(true);
        const controller = new AbortController();
        apiClient
            .get(apiInternalVisitPrioritiesPath(), {
            params: queryParams,
            signal: controller.signal
        })
            .then((response) => {
            setData(response.data.data);
        })
            .catch((err) => {
            if (!controller.signal.aborted) {
                setData([]);
                setError(err);
            }
        })
            .finally(() => {
            if (!controller.signal.aborted) {
                setLoading(false);
            }
        });
        return () => {
            controller.abort();
        };
    }, [queryParams]);
    return { data, loading, error };
};
export default useGetVisitPriorities;
