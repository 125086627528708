import React from 'react';
import clsx from 'classnames';
import PDFViewer from '@components/PDFViewer';
import { translate } from '@utils/i18n';
import Button from '@components/Button/Button';
import { PDFViewerStatus } from '../types';
import { namespace } from '../constants';
export default function PDFSection({ pdfViewerStatus, document, onChangeViewerStatus, onStartSelecting, onStopSelecting }) {
    return pdfViewerStatus !== PDFViewerStatus.Minimized ? (React.createElement("section", { className: clsx('pdfViewer', {
            expanded: pdfViewerStatus === PDFViewerStatus.Expanded
        }) },
        React.createElement("section", { className: "pdfViewerHeader" },
            React.createElement("span", { className: "title" }, document === null || document === void 0 ? void 0 : document.file_name),
            React.createElement("div", { className: "pdfViewerHeaderActions" },
                pdfViewerStatus === PDFViewerStatus.Normal && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: () => onChangeViewerStatus(PDFViewerStatus.Expanded) },
                        React.createElement("i", { className: "fa-light fa-expand" })),
                    React.createElement(Button, { onClick: () => onChangeViewerStatus(PDFViewerStatus.Minimized) },
                        React.createElement("i", { className: "fa-light fa-arrow-left-from-line" })))),
                pdfViewerStatus === PDFViewerStatus.Expanded && (React.createElement(Button, { onClick: () => onChangeViewerStatus(PDFViewerStatus.Normal) },
                    React.createElement("i", { className: "fa-light fa-compress" }))))),
        React.createElement("section", { className: "pdfSubHeader" },
            React.createElement("i", { className: "fa-light fa-circle-info" }),
            React.createElement("p", null,
                React.createElement("b", null, translate('use_select_cursor', { namespace })),
                ' ',
                React.createElement("span", null, translate('once_selected_add', { namespace })))),
        React.createElement(PDFViewer, { defaultZoom: pdfViewerStatus === PDFViewerStatus.Expanded ? 0.5 : 1, file: document === null || document === void 0 ? void 0 : document.url, className: "pdfViewerSection", onStartSelecting: onStartSelecting, onStopSelecting: onStopSelecting }))) : (React.createElement("section", { className: "pdfViewerMinimized" },
        React.createElement(Button, { onClick: () => onChangeViewerStatus(PDFViewerStatus.Normal) },
            React.createElement("i", { className: "fa-light fa-arrow-right-from-line" })),
        React.createElement("div", { className: "textContainer" },
            React.createElement("span", { className: "title" }, document === null || document === void 0 ? void 0 : document.file_name))));
}
