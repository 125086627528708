import React from 'react';
import { isValidDate } from '@fullcalendar/core/internal';
import { translate } from '@utils/i18n';
import { getDateFormat, getDurationFormat, getTimeFormat } from '../../../utils/getDatetimeFormat';
import TechnicianTypes from '../../../constants/TechnicianTypes.ts';

const namespace = 'features.work_orders';

const getVisitsColumns = (onRequestEdit, onDelete, visitStatuses, subcontractors, technicians, workOrderId) => [
  {
    dataKey: 'assigned',
    width: 140,
    label: translate('assigned_to', { namespace }),
    renderer: ({ item }) => {
      if (item.technician_id || item.subcontractor_id) {
        const techInfo = (
          item.assignee_type === 'technician' ? technicians : subcontractors[item.id] || subcontractors.default
        ).find(([, id]) => (item.assignee_type === 'technician' ? item.technician_id : item.subcontractor_id) === id);

        if (techInfo) {
          const [name] = techInfo;
          return (
            <>
              <span>{name}</span>
              <br />
              <br />
              {item.technician?.technician_type === TechnicianTypes.ScheduleOnly && (
                <span>({translate('schedule_only', { namespace })})</span>
              )}
            </>
          );
        }
      }
      return translate('unassigned', { namespace: 'features.commons' });
    }
  },
  {
    dataKey: 'scheduledDate',
    label: translate('scheduled_date', { namespace }),
    renderer: ({ item }) => {
      const scheduledDate = new Date(item?.scheduled_date);
      if (isValidDate(scheduledDate)) {
        return getDateFormat(item?.scheduled_date);
      }
      return translate('n_a', { namespace: 'features.commons' });
    }
  },
  {
    dataKey: 'scheduledTime',
    label: translate('scheduled_time', { namespace }),
    renderer: ({ item }) => {
      const scheduledDate = new Date(item?.scheduled_date);
      if (isValidDate(scheduledDate)) {
        return getTimeFormat(item?.scheduled_date);
      }
      return translate('n_a', { namespace: 'features.commons' });
    }
  },
  {
    dataKey: 'workPerformed',
    label: translate('work_performed', { namespace }),
    renderer: ({ item }) => {
      return item.work_performed ?? '';
    }
  },
  {
    dataKey: 'followUp',
    label: translate('follow_up', { namespace }),
    renderer: ({ item }) => {
      return item.follow_up ?? '';
    }
  },
  {
    dataKey: 'duration',
    label: translate('duration', { namespace }),
    renderer: ({ item }) => {
      if (!Number(item?.duration)) {
        return <span>{translate('unassigned', { namespace: 'features.commons' })}</span>;
      }
      return <span>{getDurationFormat(item?.duration)}</span>;
    }
  },
  {
    dataKey: 'visit_status',
    label: translate('status', { namespace }),
    renderer: ({ item }) => {
      if (!item?.visit_status) {
        return <span>{translate('unassigned', { namespace: 'features.commons' })}</span>;
      }
      const [label] = visitStatuses.find(([, id]) => id === item.visit_status);
      return <span>{label}</span>;
    }
  },
  {
    dataKey: 'actions',
    width: 30,
    label: '',
    renderer: ({ item }) => {
      return (
        <div className="actionsContainer">
          <button
            type="button"
            aria-label="edit"
            className="qmb-control--icon"
            title={translate('edit', { namespace })}
            onClick={() => onRequestEdit(item.id)}>
            <i className="fa-light fa-pencil" />
          </button>
          {!workOrderId && (
            <button
              type="button"
              aria-label="delete"
              className="qmb-control--icon"
              title={translate('delete', { namespace })}
              onClick={() => onDelete(item.id)}>
              <i className="fa-light fa-trash-can" />
            </button>
          )}
        </div>
      );
    }
  }
];

export default getVisitsColumns;
