import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { flattenOpacity } from '@utils/colors';
import { EventContext } from './EventContext';
import ResizeHandles from './ResizeHandles';
function EventVisit() {
    var _a, _b;
    const { event } = useContext(EventContext);
    const { clusterInfo, state, metadata, handleMouseDown } = useContext(EventContext);
    const eventCardRef = useRef(null);
    const buildingInfoRef = useRef(null);
    const [buildingInfoStyle, setBuildingInfoStyle] = useState({});
    const descriptionInfoRef = useRef(null);
    const [descriptionInfoStyle, setDescriptionInfoStyle] = useState({});
    const dynamicStyles = useMemo(() => {
        return {
            left: `${metadata.startTimeAsPercentage}%`,
            width: `${metadata.endTimeAsPercentage - metadata.startTimeAsPercentage}%`,
            height: `${(100 / clusterInfo.subdivision) * clusterInfo.span}%`,
            top: `calc((${100 / clusterInfo.subdivision}% ) * ${clusterInfo.offset})`,
            borderTop: clusterInfo.offset === 0 ? 'unset' : 'transparent',
            '--custom-status-color': `${event.status.color}4D`
        };
    }, [event, clusterInfo, metadata]);
    const dynamicClass = useMemo(() => {
        const classes = ['event__item--custom'];
        if (state === 1 /* EventState.REPOSITIONING */)
            classes.push('--dragging');
        return classes.join('');
    }, [state]);
    const handleBuildingMouseEnter = useCallback(() => {
        const div = buildingInfoRef.current;
        const eventDiv = eventCardRef.current;
        if (!div || !eventDiv)
            return;
        const buildingDivRect = div.getBoundingClientRect();
        const eventDivRect = eventDiv.getBoundingClientRect();
        setBuildingInfoStyle({
            left: `${buildingDivRect.left - eventDivRect.left - 6}px`,
            zIndex: 50,
            position: 'absolute',
            minWidth: 'max-content',
            background: '#fff',
            padding: '0 2px',
            borderRadius: '4px',
            border: `4px solid ${flattenOpacity(`${event.status.color}4D`)}`
        });
    }, []);
    const handleBuildingMouseLeave = useCallback(() => {
        setBuildingInfoStyle({});
    }, []);
    const handleDescriptionMouseEnter = useCallback(() => {
        const div = descriptionInfoRef.current;
        const eventDiv = eventCardRef.current;
        if (!div || !eventDiv)
            return;
        const descriptionDivRect = div.getBoundingClientRect();
        const eventDivRect = eventDiv.getBoundingClientRect();
        setDescriptionInfoStyle({
            left: `${descriptionDivRect.left - eventDivRect.left - 6}px`,
            zIndex: 50,
            position: 'absolute',
            minWidth: 'max-content',
            background: '#fff',
            padding: '0 2px',
            borderRadius: '4px',
            border: `4px solid ${flattenOpacity(`${event.status.color}4D`)}`
        });
    }, []);
    const handleDescriptionMouseLeave = useCallback(() => {
        setDescriptionInfoStyle({});
    }, []);
    return (React.createElement("div", { className: dynamicClass, style: dynamicStyles, onMouseDown: (e) => handleMouseDown(e.nativeEvent), ref: eventCardRef },
        React.createElement("div", { className: "event__card" },
            React.createElement(ResizeHandles, null),
            React.createElement("div", { className: "event__prefix" },
                React.createElement("div", { className: "event__indicator", style: { background: event.status.color } })),
            React.createElement("div", { className: "event__body" },
                React.createElement("div", { className: "event__primary" },
                    React.createElement("span", { className: "event__type" },
                        React.createElement(Icon, { type: event.visitable.type })),
                    React.createElement("span", { className: "event__time" }, event.start.format('h:mma')),
                    React.createElement("span", { className: "event__bullet_wrapper" },
                        React.createElement("span", { className: "event__bullet" })),
                    React.createElement("div", { className: "event__building", style: buildingInfoStyle, ref: buildingInfoRef, onMouseEnter: handleBuildingMouseEnter, onMouseLeave: handleBuildingMouseLeave },
                        React.createElement("span", { className: "event__building_name" }, (_a = event.building) === null || _a === void 0 ? void 0 : _a.name),
                        React.createElement("span", { className: "event__building_address" }, (_b = event.building) === null || _b === void 0 ? void 0 : _b.address))),
                React.createElement("div", { className: "event__secondary" },
                    React.createElement("span", { className: "event__description", style: descriptionInfoStyle, ref: descriptionInfoRef, onMouseEnter: handleDescriptionMouseEnter, onMouseLeave: handleDescriptionMouseLeave }, event.description))))));
}
function Icon({ type }) {
    switch (type) {
        case "WorkOrder" /* VisitableType.SERVICE */:
            return React.createElement("i", { className: "fa-light fa-screwdriver-wrench" });
        case "Inspection" /* VisitableType.INSPECTION */:
            return React.createElement("i", { className: "fa-light fa-clipboard-check" });
        default:
            return React.createElement("i", { className: "fa-light fa-square-question" });
    }
}
export default EventVisit;
