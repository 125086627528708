var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
import React, { forwardRef } from 'react';
import clsx from 'classnames';
import { ButtonSize, ButtonVariant } from './types';
import './ButtonStyles.scss';
const Button = forwardRef((_a, ref) => {
    var { children, size = ButtonSize.Auto, variant = ButtonVariant.Empty, width, className = '', type = 'button' } = _a, props = __rest(_a, ["children", "size", "variant", "width", "className", "type"]);
    return (React.createElement("button", Object.assign({ ref: ref, type: type, style: width ? { width: `${width}px`, display: 'inline-flex', justifyContent: 'center' } : {}, className: clsx('qmb-control custom-button', className, {
            'full-size': size === ButtonSize.Full,
            'blue-variant': variant === ButtonVariant.BlueAccent,
            'gray-variant': variant === ButtonVariant.GrayAccent,
            'primary-variant': variant === ButtonVariant.Primary,
            'qmb-control--highlight': variant === ButtonVariant.Highlight,
            'qmb-control--highlight purple-variant': variant === ButtonVariant.PurpleHighlight,
            'qmb-control--highlight red-variant': variant === ButtonVariant.RedHighlight,
            'outline-variant': variant === ButtonVariant.Outline
        }) }, props), children));
});
Button.displayName = 'Button';
export default Button;
