import React, { useMemo } from 'react';
import useGetVisitStatuses from '@hooks/requests/visit-statuses.get.hook';
import LegendStatus from './LegendStatus';
function Legend() {
    const { inspectionStatusParams, visitStatusParams } = useMemo(() => {
        const a = {
            select: 'all',
            filter: {
                type: "Inspection" /* VisitableType.INSPECTION */
            }
        };
        const b = {
            select: 'all',
            filter: {
                type: "WorkOrder" /* VisitableType.SERVICE */
            }
        };
        return { inspectionStatusParams: a, visitStatusParams: b };
    }, []);
    const { data: inspectionStatuses } = useGetVisitStatuses(inspectionStatusParams);
    const { data: visitStatuses } = useGetVisitStatuses(visitStatusParams);
    const statuses = useMemo(() => {
        // Merge inspection and visit statuses that have the same name and color
        const mergedStatuses = [...inspectionStatuses, ...visitStatuses];
        const seenStatuses = new Set();
        const uniqueStatuses = mergedStatuses.filter((status) => {
            if (seenStatuses.has(status.label + status.color)) {
                return false;
            }
            seenStatuses.add(status.label + status.color);
            return true;
        });
        return uniqueStatuses.map((status) => React.createElement(LegendStatus, { key: `legend_status_${status.key}`, status: status }));
    }, [inspectionStatuses, visitStatuses]);
    return (React.createElement("div", { className: "schedule__legend" },
        React.createElement("div", { className: "legend__content" },
            React.createElement("span", { className: "legend__title" }, "Legend:"),
            React.createElement("div", { className: "legend__collection" }, statuses))));
}
export default Legend;
