import React, { useState, useRef } from 'react';
import apiClient from '@utils/api-client';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { requeueQbdSettingsPath } from '../../../../routes';
function RequeueInput() {
    const [type, setType] = useState('no');
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const input = useRef(null);
    const handleSubmit = () => {
        if (input.current) {
            setSubmitDisabled(true);
            apiClient
                .post(requeueQbdSettingsPath(), {
                type,
                value: input.current.value
            })
                .then((response) => {
                var _a;
                const newRecord = {
                    action: response.data.action,
                    requested: dayjs(response.data.createdAt).format('MM/DD/YY HH:mm:ss'),
                    invoice: (_a = response.data.ipInvoiceId) !== null && _a !== void 0 ? _a : 'N/A'
                };
                const pendingGrid = $('#pending-grid').data('kendoGrid');
                pendingGrid.dataSource.insert(newRecord);
                $('#pending-grid tfoot').hide();
                input.current.value = '';
                toast.success(`Successfully requeued invoice.`);
            })
                .catch((error) => {
                toast.error(`Unable to requeue invoice.`);
            })
                .finally(() => {
                setSubmitDisabled(false);
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'flex', justifyContent: 'end' } },
            React.createElement("div", { className: "qmb-input--number --x-full--with-toggle form__field" },
                React.createElement("input", { ref: input, type: "text" }),
                React.createElement("label", { className: "qmb-label" }, type === 'id' ? 'Invoice ID' : 'Invoice No.'),
                React.createElement("div", { className: "input__toggle-container--right" },
                    React.createElement("div", { className: "qmb-radio--alt" },
                        React.createElement("input", { id: "invoice_no", name: "identifier", type: "radio", onClick: () => setType('no'), checked: type === 'no', readOnly: true }),
                        React.createElement("label", { htmlFor: "invoice_no" }, "No.")),
                    React.createElement("div", { className: "qmb-radio--alt" },
                        React.createElement("input", { id: "invoice_id", name: "identifier", type: "radio", onClick: () => setType('id'), checked: type === 'id', readOnly: true }),
                        React.createElement("label", { htmlFor: "invoice_id" }, "ID")))),
            React.createElement("button", { className: "qmb-button--submit", onClick: handleSubmit, disabled: submitDisabled }, "Requeue"))));
}
export default RequeueInput;
