/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Loader, LoaderSizes } from './Loader';
import './ToastLoader.scss';
function LoaderToast({ show, closable }) {
    const [showLoader, setShowLoader] = useState(show);
    useEffect(() => {
        setShowLoader(show);
    }, [show]);
    return (React.createElement(NotificationGroup, { style: {
            right: 5,
            bottom: 5,
            alignItems: 'flex-start',
            flexWrap: 'wrap-reverse',
            zIndex: 100
        } }, showLoader && (React.createElement(Notification, { type: {
            style: 'none',
            icon: false
        }, closable: closable, onClose: () => setShowLoader(false) },
        React.createElement("div", { className: "toastLoaderContainer" },
            React.createElement(Loader, { size: LoaderSizes.small }),
            " ",
            React.createElement("span", null, "Processing"))))));
}
export default React.memo(LoaderToast);
