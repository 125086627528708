import React, { useContext, useEffect, useMemo, useState } from 'react';
import { dateClient } from '@utils/date-client';
import { getTimeAsPercentageOfDay } from '@utils/functions/scheduler.functions';
import { TimeZoneContext } from '../../../contexts/timezone-context';
import { SchedulerContext } from '../Contexts/SchedulerContext';
function CurrentTimeIndicator2() {
    const { timezone } = useContext(TimeZoneContext);
    const { activeDate } = useContext(SchedulerContext);
    const [trigger, setTrigger] = useState(0);
    // Update the position of the indicator every 5 minutes
    useEffect(() => {
        const interval = setInterval(() => {
            setTrigger((prev) => prev + 1);
        }, 300000);
        return () => clearInterval(interval);
    }, []);
    const dynamicStyle = useMemo(() => {
        var _a;
        const now = dateClient().tz(timezone);
        const header = document.getElementById('scheduler-time-header');
        if (!now.isSame(activeDate, 'day') || !header)
            return { display: 'none' };
        const percentageOfDay = getTimeAsPercentageOfDay(now, activeDate) / 100;
        const headerDimensions = header.getBoundingClientRect();
        return { right: (1 - percentageOfDay) * ((_a = headerDimensions === null || headerDimensions === void 0 ? void 0 : headerDimensions.width) !== null && _a !== void 0 ? _a : 100), pointerEvents: 'none' };
    }, [activeDate, trigger, timezone]);
    useEffect(() => {
        // On init, default the scheduler scroll to the current time
        const now = dateClient().tz(timezone);
        const header = document.getElementById('scheduler-time-header');
        const headerDimensions = header.getBoundingClientRect();
        const percentageOfDay = getTimeAsPercentageOfDay(now, activeDate) / 100;
        document.getElementById('scheduler').scrollLeft = percentageOfDay * headerDimensions.width - 200;
    }, []);
    return (React.createElement("div", { className: "schedule__current-time", style: dynamicStyle },
        React.createElement("i", { className: "schedule__current-point" })));
}
export default CurrentTimeIndicator2;
