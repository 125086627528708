import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@components/Table';
import I18n, { translate } from '@utils/i18n';
import Modal from '@components/Modal';
import './RecipientsModal.scss';

const namespace = 'features.settings.deficiency_ai';

function RecipientModal({ isModalOpen, onClose, users, onUserSwitch, onSelectAll, onUsersUpdate }) {
  const [search, setSearch] = React.useState('');

  const selectedUsersCount = React.useMemo(() => users.reduce((count, user) => count + (user.subscribed ? 1 : 0), 0));

  const allSelected = React.useMemo(() => users.every((user) => user.subscribed), [users]);

  const selectAll = () => {
    onSelectAll(!allSelected);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setSearch('');
    }
  }, [isModalOpen]);

  const selectColumns = [
    {
      dataKey: 'subscribed',
      label: (
        <label className="qmb-checkbox">
          <input name="all" type="checkbox" checked={allSelected} onChange={selectAll} readOnly />
          <strong>{I18n.t('generic.all')}</strong>
        </label>
      ),
      renderer: ({ item }) => {
        return (
          <label className="qmb-checkbox">
            <input type="checkbox" name={item.id} checked={item.subscribed} onChange={onUserSwitch(item)} readOnly />
            <span>{item.email}</span>
          </label>
        );
      }
    }
  ];

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const usersFiltered = React.useMemo(() => {
    if (search === '') return users;

    const re = new RegExp(search, 'i');

    return users.filter((user) => re.test(user.email));
  }, [search, users]);

  return (
    <Modal
      title={translate('update_notification_recipients', { namespace })}
      className="qmb-dialog--modal"
      isOpen={isModalOpen}
      onClose={onClose}
      width={930}
      height={480}>
      <section className="dialog__body">
        <div className="qmb-input--search pull-right" style={{ width: '200px', marginBottom: '20px', zIndex: 0 }}>
          <input placeholder={I18n.t('generic.search')} id="search" value={search} onChange={onSearchChange} />
          <i className="fa-light fa-search" aria-hidden="true" />
        </div>
        <Table data={usersFiltered} columns={selectColumns} size="full" variant="listing" />
        {usersFiltered.length === 0 && (
          <section className="noResulsContainer">
            {translate('no_matches_found', { namespace: 'features.commons' })}
          </section>
        )}
        <section
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#fff',
            margin: '0 -25px -25px',
            padding: '5px',
            borderTop: '1px solid'
          }}>
          <section style={{ display: 'flex', justifyContent: 'space-between' }}>
            <section style={{ margin: 'auto 0px auto 5px' }}>
              {selectedUsersCount === 0 && <span>{translate('least_one_recipient', { namespace })}</span>}
              {selectedUsersCount > 0 && (
                <span>
                  {selectedUsersCount} {translate('selected', { namespace })}
                </span>
              )}
            </section>
            <section>
              <button type="button" className="qmb-control" onClick={onClose} style={{ marginRight: '10px' }}>
                {I18n.t('generic.cancel')}
              </button>
              <button
                type="button"
                disabled={selectedUsersCount === 0}
                className="qmb-control--highlight"
                onClick={onUsersUpdate}>
                {translate('update_recipients', { namespace })}
              </button>
            </section>
          </section>
        </section>
      </section>
    </Modal>
  );
}

RecipientModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  onUserSwitch: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onUsersUpdate: PropTypes.func.isRequired
};

export default RecipientModal;
