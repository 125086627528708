/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import Button from '@components/Button/Button';
import useDebounceFn from '@hooks/useDebounceFn';
import './PDFViewer.scss';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const MagnifingFactor = 0.5;
const MinZoomLevel = 0.5;
const InitialZoomLevel = 1;
const MarginalWidthChangeGrade = 5;
function PDFViewer({ file, className, pageHeight, pageWidth, onStartSelecting, onStopSelecting, defaultZoom }) {
    const [numPages, setNumPages] = useState();
    const [indexSelected, setIndexSelected] = useState(0);
    const pageRefs = useRef([]);
    const wrapperRef = useRef(null);
    const [autoPageWidth, setAutoPageWidth] = useState(0);
    const pageWidthRef = useRef(autoPageWidth);
    const debouncedSetAutoPageWidth = useDebounceFn(setAutoPageWidth, 300);
    const parentElement = useRef(null);
    const [zoomLevel, setZoomLevel] = useState(defaultZoom || InitialZoomLevel);
    const observer = useMemo(() => new ResizeObserver((entries) => {
        const [newElement] = entries;
        const { width } = newElement.contentRect;
        if (Math.abs(width - pageWidthRef.current) <= MarginalWidthChangeGrade)
            return;
        debouncedSetAutoPageWidth(width);
    }), [debouncedSetAutoPageWidth]);
    useEffect(() => {
        setZoomLevel(defaultZoom || InitialZoomLevel);
    }, [defaultZoom]);
    useEffect(() => {
        setZoomLevel(defaultZoom || InitialZoomLevel);
        pageWidthRef.current = autoPageWidth;
    }, [autoPageWidth]);
    useEffect(() => {
        pageRefs.current = new Array(numPages).fill(null).map(() => React.createRef());
    }, [numPages]);
    useEffect(() => {
        if (!file) {
            observer.disconnect();
        }
        return () => {
            observer.disconnect();
        };
    }, [file]);
    const onDocumentLoadSuccess = ({ numPages: pageAmount }) => {
        setNumPages(pageAmount);
        if (pageAmount === 0)
            return;
        setTimeout(() => {
            observer.disconnect();
            const element = parentElement.current;
            observer.observe(element.pages.current[0].parentElement);
        }, 300);
    };
    const onClickPage = (index) => {
        var _a, _b;
        setIndexSelected(index);
        (_b = (_a = pageRefs.current[index]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.scrollIntoView();
    };
    const checkSelection = () => {
        const element = parentElement.current;
        const selection = window.getSelection();
        if ((selection === null || selection === void 0 ? void 0 : selection.toString().trim()) !== '' && (selection === null || selection === void 0 ? void 0 : selection.anchorNode)) {
            const page = element === null || element === void 0 ? void 0 : element.pages.current.findIndex((pageElement) => {
                return pageElement === null || pageElement === void 0 ? void 0 : pageElement.contains(selection === null || selection === void 0 ? void 0 : selection.anchorNode);
            });
            if (page !== undefined) {
                onStartSelecting === null || onStartSelecting === void 0 ? void 0 : onStartSelecting({ page: page + 1, text: selection === null || selection === void 0 ? void 0 : selection.toString() });
            }
            else {
                onStopSelecting === null || onStopSelecting === void 0 ? void 0 : onStopSelecting();
            }
        }
        else {
            onStopSelecting === null || onStopSelecting === void 0 ? void 0 : onStopSelecting();
        }
    };
    return (React.createElement(Document, { ref: parentElement, onMouseUp: () => {
            setTimeout(checkSelection, 200);
        }, file: file, onLoadSuccess: onDocumentLoadSuccess, className: `pdfViewerContent ${className !== null && className !== void 0 ? className : ''}` },
        React.createElement("section", { className: "pagesViewer" }, [...Array(numPages)].map((_, index) => (React.createElement("section", { role: "presentation", key: index, className: "itemSelector", onClick: () => onClickPage(index) },
            React.createElement(Page, { className: `pdfViewerPage ${index === indexSelected ? 'selected' : ''}`, pageNumber: index + 1, height: 93, renderAnnotationLayer: false, renderTextLayer: false }),
            React.createElement("span", { className: "pageIndex" }, index + 1))))),
        React.createElement("section", { ref: wrapperRef, className: "pageDetail pagesViewer" },
            [...Array(numPages)].map((_, index) => (React.createElement(Page, { inputRef: pageRefs.current[index], key: index, className: "pdfViewerPage", pageNumber: index + 1, width: pageWidth !== null && pageWidth !== void 0 ? pageWidth : Math.floor(autoPageWidth), height: pageHeight, scale: zoomLevel, renderAnnotationLayer: false, renderTextLayer: true }))),
            React.createElement("section", { className: "pdfViewerZoom" },
                React.createElement(Button, { role: "presentation", onClick: () => setZoomLevel(zoomLevel + MagnifingFactor) },
                    React.createElement("i", { role: "presentation", className: "fa-light fa-magnifying-glass-plus" })),
                React.createElement("i", { role: "presentation", className: "fa-light fa-grid-line-vertical" }),
                React.createElement(Button, { role: "presentation", onClick: () => setZoomLevel(zoomLevel - MagnifingFactor > MinZoomLevel ? zoomLevel - MagnifingFactor : MinZoomLevel) },
                    React.createElement("i", { role: "presentation", className: "fa-light fa-magnifying-glass-minus" }))))));
}
export default React.memo(PDFViewer);
