/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { apiInternalVisitsPath, checkAvailableTechniciansPath } from 'routes';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Duration } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import api from '@utils/axios';
import { translate } from '@utils/i18n';
import Select from '@components/Select/Select';
import TimeInput from '@components/TimeInput/TimeInput';
import Modal from '@components/Modal';
import { Button, ButtonVariant, LoadableButton } from '@components/Button';
import DateInput from '@components/DateInput/DateInput';
import FormTypeSelector from './components/FormTypeSelector';
import TextInput from './components/TextInput';
import InputTextArea, { StyleVariant } from '@components/InputTextArea/InputTextArea';
import VisitPriorityDropdown from '@components/Dropdowns/VisitPriorityDropdown';
import { VisitPriority } from './constants.js';

const namespace = 'features.work_orders';

export default function NewVisitModal({
  woId,
  isOpen,
  onClose,
  defaultTechnicianId,
  defaultSubcontractorId,
  techOptions,
  vendorOptions,
  statusesOptions,
  onAddComplete,
  defaultAssigneeType,
  defaultVisitStatus,
  priorityOptions
}) {
  const [loading, setIsLoading] = useState(false);
  const [isVendor, setIsVendor] = useState(defaultAssigneeType === 'subcontractor');
  const [checkAvailableNotification, setCheckAvailableNotification] = useState({ message: '', data: null });
  const { register, reset, handleSubmit, control, watch } = useForm({
    defaultValues: {
      technicianId: defaultTechnicianId || '',
      subcontractorId: defaultSubcontractorId || '',
      status: defaultVisitStatus,
      scheduledDate: new Date(),
      days: 0,
      hours: 1,
      minutes: 0,
      notesToVendor: '',
      poNumber: '',
      billingLimit: 0,
      priority: VisitPriority.NORMAL,
      priorityNote: ''
    }
  });
  
  const priority = watch('priority');
  const isHighPriority = priority === VisitPriority.HIGH;

  useEffect(() => {
    let controller = new AbortController();

    const subscription = watch((value, { name }) => {
      if (!['minutes', 'hours', 'days', 'technicianId', 'scheduledDate'].includes(name)) return;
      if (!parseInt(value.technicianId, 10)) {
        setCheckAvailableNotification({ message: '', data: null });
        return;
      }

      controller.abort();
      controller = new AbortController();

      api
        .get(checkAvailableTechniciansPath({ format: 'json' }), {
          signal: controller.signal,
          params: {
            technician_id: value.technicianId,
            scheduled_date: value.scheduledDate,
            duration: Duration.fromObject({
              days: value.days,
              hours: value.hours,
              minutes: value.minutes
            }).as('minutes')
          }
        })
        .then(() => setCheckAvailableNotification({ message: '', data: null }))
        .catch((error) =>
          setCheckAvailableNotification({
            message: error?.response?.data?.message,
            data: error?.response?.data?.data
          })
        );
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = (newVisitInfo) => {
    if (woId) return onSubmitOnPersisted(newVisitInfo);

    return onSubmitOnNewRecord(newVisitInfo);
  };

  const onSubmitOnNewRecord = (newVisitInfo) => {
    onAddComplete({
      scheduled_date: newVisitInfo.scheduledDate,
      duration: Number(newVisitInfo.days) * 1440 + Number(newVisitInfo.hours * 60) + Number(newVisitInfo.minutes),
      technician_id: !isVendor ? Number(newVisitInfo.technicianId) || '' : '',
      id: Math.random(),
      notes: isVendor ? newVisitInfo.notesToVendor : undefined,
      visit_status: newVisitInfo.status ? newVisitInfo.status : undefined,
      subcontractor_id: isVendor ? Number(newVisitInfo.subcontractorId) || '' : '',
      subcontractor_po_number: newVisitInfo.poNumber ? newVisitInfo.poNumber : undefined,
      subcontractor_billing_limit: newVisitInfo.billingLimit ? newVisitInfo.billingLimit : undefined,
      assignee_type: isVendor ? 'subcontractor' : 'technician',
      priority: newVisitInfo.priority,
      priority_note: newVisitInfo.priority === VisitPriority.HIGH ? newVisitInfo.priorityNote : null
    });
    fullReset();
  };

  const onSubmitOnPersisted = async (newVisitInfo) => {
    try {
      setIsLoading(true);
      const visitInfo = {
        scheduled_date: new Date(newVisitInfo.scheduledDate),
        duration: Number(newVisitInfo.days) * 1440 + Number(newVisitInfo.hours * 60) + Number(newVisitInfo.minutes),
        technician_id: !isVendor ? newVisitInfo.technicianId ?? null : null,
        work_order_id: woId,
        notes: isVendor ? newVisitInfo.notesToVendor : undefined,
        visit_status: newVisitInfo.status ? newVisitInfo.status : undefined,
        subcontractor_id: isVendor ? newVisitInfo.subcontractorId ?? null : null,
        subcontractor_po_number: newVisitInfo.poNumber ? newVisitInfo.poNumber : undefined,
        subcontractor_billing_limit: newVisitInfo.billingLimit ? newVisitInfo.billingLimit : undefined,
        assignee_type: isVendor ? 'subcontractor' : 'technician',
        priority: newVisitInfo.priority,
        priority_note: newVisitInfo.priority === VisitPriority.HIGH ? newVisitInfo.priorityNote : null
      };

      const { data: res } = await api.post(apiInternalVisitsPath({ format: 'json' }), { visit: visitInfo });

      onAddComplete(res.data);
      fullReset();
    } catch (e) {
      toast.error(translate('add_error', { namespace }), {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fullReset = () => {
    reset();
    setIsVendor(defaultAssigneeType === 'subcontractor');
    setCheckAvailableNotification({ message: '', data: null });
  };

  const onClickCancel = () => {
    onClose();
    fullReset();
  };

  const onChangeAsigneeType = ({ isVendor: newIsVendor }) => {
    setIsVendor(newIsVendor);
  };

  const technicianOptions = techOptions.map(([name, id]) => ({ label: name, value: id }));
  const subcontractorOptions = vendorOptions.default.map(([name, id]) => ({ label: name, value: id }));
  const statuses = statusesOptions.map(([name, id]) => ({ label: name, value: id }));
  const priorities = priorityOptions.map(([name, id]) => ({ label: name, value: id }));

  return (
    <Modal
      title={translate('new_visit', { namespace })}
      isOpen={isOpen}
      onClose={onClickCancel}
      width={600}
      height={isVendor ? 510 : 368}>
      <section className="qmb-modal__body visitModal">
        <form className="modalForm" onSubmit={handleSubmit(onSubmit)}>
          <div className="formSection">
            <FormTypeSelector isVendor={isVendor} onChange={onChangeAsigneeType} />
          </div>
          <div className="formSection equalChildrens">
            {!isVendor && (
              <Select
                {...register('technicianId')}
                id="technicianId"
                noOptionLabel={translate('unassigned', { namespace })}
                label={translate('technician', { namespace })}
                options={technicianOptions}
              />
            )}
            {isVendor && (
              <Select
                {...register('subcontractorId')}
                id="subcontractorId"
                noOptionLabel={translate('unassigned', { namespace })}
                label={translate('subcontractor', { namespace })}
                options={subcontractorOptions}
              />
            )}
            <Select {...register('status')} label={translate('status', { namespace })} id="status" options={statuses} />
          </div>
          {isVendor && (
            <div className="formSection">
              <div className="equalChildrens">
                <Controller
                  name="poNumber"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      id="poNumber"
                      value={field.value}
                      name={field.name}
                      onChange={field.onChange}
                      label={`${translate('po', { namespace })} #`}
                    />
                  )}
                />
                <Controller
                  name="billingLimit"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      id="billingLimit"
                      value={field.value}
                      name={field.name}
                      onChange={field.onChange}
                      type="number"
                      min={0}
                      label={translate('billing_limit', { namespace })}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="formSection">
            <div className="equalChildrens">
              <Controller
                name="scheduledDate"
                control={control}
                render={({ field }) => (
                  <DateInput
                    id="scheduledDate"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    label={translate('scheduled_date', { namespace })}
                  />
                )}
              />
              <Controller
                name="scheduledDate"
                control={control}
                render={({ field }) => (
                  <TimeInput
                    id="scheduledDate"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    label={translate('start_time', { namespace })}
                  />
                )}
              />
            </div>
            <div className="equalChildrens">
              <span>{translate('duration', { namespace })}:</span>
              <Controller
                name="days"
                control={control}
                render={({ field }) => (
                  <TextInput
                    id="days"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    label={translate('days', { namespace: 'features.commons' })}
                    type="number"
                    min={0}
                  />
                )}
              />
              <Controller
                name="hours"
                control={control}
                render={({ field }) => (
                  <TextInput
                    id="hours"
                    type="number"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    min={0}
                    label={translate('hours', { namespace: 'features.commons' })}
                  />
                )}
              />
              <Controller
                name="minutes"
                control={control}
                render={({ field }) => (
                  <TextInput
                    id="minutes"
                    type="number"
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    min={0}
                    label={translate('minutes', { namespace: 'features.commons' })}
                  />
                )}
              />
            </div>
          </div>
          <div className="form__set--vertical--compact">
            <Controller
              name="priority"
              control={control}
              render={({ field }) => (
                <VisitPriorityDropdown
                  visitPriorityList={priorities}
                  selectedValue={field.value}
                  onChange={field.onChange}
                  disabled={false}
                />
              )}
            />

            {isHighPriority && (
              <Controller
                name="priorityNote"
                control={control}
                render={({ field }) => (
                  <InputTextArea
                    label={translate('priority_note', { namespace })}
                    value={field.value}
                    name={field.name}
                    buttonControlled={false}
                    variant={StyleVariant.Light}
                    onChange={field.onChange}
                  />
                )}
              />
            )}
          </div>
          <div className="modalActionContainer">
            {!isVendor && (
              <div style={{ margin: 'auto' }}>
                {checkAvailableNotification.message}
                <br />
                {checkAvailableNotification.data && (
                  <a href={checkAvailableNotification.data.link} target="_blank" rel="noreferrer">
                    {checkAvailableNotification.data.text_link}
                  </a>
                )}
              </div>
            )}
            <Button onClick={onClickCancel}>{translate('cancel', { namespace: 'features.commons' })}</Button>
            <LoadableButton type="submit" variant={ButtonVariant.Primary} loading={loading}>
              {translate('save', { namespace: 'features.commons' })}
            </LoadableButton>
          </div>
        </form>
      </section>
    </Modal>
  );
}

NewVisitModal.propTypes = {
  defaultAssigneeType: PropTypes.string.isRequired,
  woId: PropTypes.number,
  vendorOptions: PropTypes.object,
  onAddVisit: PropTypes.func,
  isOpen: PropTypes.bool,
  defaultTechnicianId: PropTypes.number,
  defaultSubcontractorId: PropTypes.number,
  onClose: PropTypes.func,
  techOptions: PropTypes.array,
  priorityOptions: PropTypes.array,
  statusesOptions: PropTypes.array,
  onAddComplete: PropTypes.func.isRequired
};

NewVisitModal.defaultProps = {
  vendorOptions: {},
  woId: null,
  onAddVisit: () => {},
  defaultTechnicianId: null,
  defaultSubcontractorId: null,
  isOpen: false,
  onClose: () => {},
  techOptions: [],
  priorityOptions: [],
  statusesOptions: []
};
