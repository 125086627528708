import React, { useMemo, useRef } from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
function LegendStatus(params) {
    const tooltip = useRef(null);
    const { foregroundColor, backgroundColor } = useMemo(() => {
        return { foregroundColor: params.status.color, backgroundColor: `${params.status.color}4D` };
    }, [params.status]);
    return (React.createElement("div", { className: "legend__status", onMouseOver: (event) => tooltip.current && tooltip.current.handleMouseOver(event), onMouseOut: (event) => tooltip.current && tooltip.current.handleMouseOut(event) },
        React.createElement("div", { className: "status__indicator", style: { background: backgroundColor } },
            React.createElement("div", { className: "status__indicator_rectangle", style: { background: foregroundColor } })),
        React.createElement("span", { className: "status__name", title: params.status.description }, params.status.label),
        React.createElement(Tooltip, { ref: tooltip, anchorElement: "target", position: "top" })));
}
export default LegendStatus;
