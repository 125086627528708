import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import './Visits.scss';
import Accordion from '@components/Accordion';
import Table, { TableSize } from '@components/Table';
import { Button, ButtonSize, ButtonVariant } from '@components/Button';
import getVisitsColumns from './visitsColumns';
import NewVisitModal from './NewVisitModal';
import EditVisitModal from './EditVisitModal';

const namespace = 'features.work_orders';

function VisitsSection({
  title,
  visits: initialVisits,
  workOrderId,
  subcontractors,
  technicians,
  priorities,
  visitStatuses,
  defaultTechnicianId,
  defaultSubcontractorId,
  defaultAssigneeType,
  buildingId,
  defaultVisitStatus
}) {
  const [isAddOpen, setIsAddOpen] = React.useState(false);
  const [visitEditing, setVisitEditing] = React.useState(null);
  const [visits, setVisits] = React.useState(initialVisits);

  const onRequestEdit = (visitId) => {
    const selectedVisit = visits.find((visit) => visit.id === visitId);
    setVisitEditing(selectedVisit);
  };

  React.useEffect(() => {
    if (visits.every((visit) => visit.id)) return;

    setVisits(visits.map((visit) => ({ ...visit, id: Math.random() })));
  }, []);

  const onRequestAdd = () => {
    setIsAddOpen(true);
  };

  const onAddComplete = (newVisitInfo) => {
    setVisits([newVisitInfo, ...visits]);
    setIsAddOpen(false);
  };

  const onEditComplete = (newVisitInfo) => {
    setVisits(visits.map((visit) => (visit.id === newVisitInfo.id ? { ...visit, ...newVisitInfo } : visit)));
    setVisitEditing(null);
  };

  const onDelete = (id) => {
    const index = visits.findIndex((visit) => visit.id === id);

    setVisits([...visits.slice(0, index), ...visits.slice(index + 1)]);
  };

  return (
    <React.Fragment key="index">
      <div className="span10 visitSection">
        <Accordion
          defaultExpanded
          title={
            <span className="header" id="work-order-visits">
              {title}
            </span>
          }>
          <div className="visitSectionContent">
            {visits.length > 0 && (
              <Table
                size={TableSize.Full}
                data={visits}
                columns={getVisitsColumns(
                  onRequestEdit,
                  onDelete,
                  visitStatuses,
                  subcontractors,
                  technicians,
                  workOrderId
                )}
              />
            )}
            <Button onClick={onRequestAdd} variant={ButtonVariant.BlueAccent} size={ButtonSize.Full}>
              <i className="fa-light fa-circle-plus" />
              {translate('add_visit', { namespace })}
            </Button>
          </div>
        </Accordion>
      </div>

      <NewVisitModal
        woId={workOrderId}
        vendorOptions={subcontractors}
        techOptions={technicians}
        priorityOptions={priorities}
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
        defaultTechnicianId={defaultTechnicianId}
        defaultSubcontractorId={defaultSubcontractorId}
        statusesOptions={visitStatuses}
        onAddComplete={onAddComplete}
        defaultAssigneeType={defaultAssigneeType}
        defaultVisitStatus={defaultVisitStatus}
      />
      <EditVisitModal
        woId={workOrderId}
        vendorOptions={subcontractors}
        techOptions={technicians}
        priorityOptions={priorities}
        visit={visitEditing}
        isOpen={!!visitEditing}
        onClose={() => setVisitEditing(null)}
        statusesOptions={visitStatuses}
        onEditComplete={onEditComplete}
        defaultTechnicianId={defaultTechnicianId}
        defaultSubcontractorId={defaultSubcontractorId}
      />
      {!workOrderId &&
        visits.map((visit, index) => (
          <React.Fragment key={index}>
            <input type="hidden" name={`work_order[visits_attributes][${index}][building_id]`} value={buildingId} />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][scheduled_date]`}
              value={new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }).format(new Date(visit.scheduled_date))}
            />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][scheduled_time]`}
              value={new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' }).format(
                new Date(visit.scheduled_date)
              )}
            />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][duration_days]`}
              value={Math.floor(visit.duration / (24 * 60))}
            />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][duration_hours]`}
              value={Math.floor((visit.duration % (24 * 60)) / 60)}
            />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][duration_minutes]`}
              value={visit.duration % 60}
            />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][technician_id]`}
              value={visit.technician_id}
            />
            <input type="hidden" name={`work_order[visits_attributes][${index}][notes]`} value={visit.notes} />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][visit_status]`}
              value={visit.visit_status}
            />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][subcontractor_id]`}
              value={visit.subcontractor_id}
            />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][subcontractor_po_number]`}
              value={visit.subcontractor_po_number}
            />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][work_performed]`}
              value={visit.work_performed}
            />
            <input type="hidden" name={`work_order[visits_attributes][${index}][follow_up]`} value={visit.follow_up} />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][subcontractor_billing_limit]`}
              value={visit.subcontractor_billing_limit}
            />
            <input
              type="hidden"
              name={`work_order[visits_attributes][${index}][assignee_type]`}
              value={visit.assignee_type}
            />
          </React.Fragment>
        ))}
    </React.Fragment>
  );
}

VisitsSection.propTypes = {
  title: PropTypes.string,
  visits: PropTypes.array,
  workOrderId: PropTypes.number,
  technicians: PropTypes.array,
  priorities: PropTypes.array,
  defaultTechnicianId: PropTypes.number,
  defaultSubcontractorId: PropTypes.number,
  defaultAssigneeType: PropTypes.string.isRequired,
  buildingId: PropTypes.number.isRequired,
  subcontractors: PropTypes.object,
  visitStatuses: PropTypes.array,
  defaultVisitStatus: PropTypes.string.isRequired
};

VisitsSection.defaultProps = {
  title: '',
  visits: [],
  technicians: [],
  priorities: [],
  defaultTechnicianId: null,
  workOrderId: null,
  defaultSubcontractorId: null,
  subcontractors: {},
  visitStatuses: []
};

export default VisitsSection;
