/* eslint-disable @typescript-eslint/naming-convention */
import { AIDeficiencyReviewStatus, ReviewStatus, IPDeficiencyStatus } from './types';
// eslint-disable-next-line import/prefer-default-export
export const PollingInterval = 10000;
export const ToastDuration = 3000;
export const namespace = 'features.documents';
export const MaxInfoItemPerCard = 3;
export const StatusIcons = {
    [AIDeficiencyReviewStatus.WaitingReview]: 'statusIcon fa-light fa-timer waiting',
    [AIDeficiencyReviewStatus.Incorrect]: 'statusIcon fa-light fa-xmark incorrect',
    [AIDeficiencyReviewStatus.Correct]: 'statusIcon fa-light fa-check correct',
    [AIDeficiencyReviewStatus.Removed]: 'statusIcon fa-light fa-xmark'
};
export const StatusAllowedDefDetails = [ReviewStatus.NeedsReview, ReviewStatus.InProgress];
export const InitialDeficiency = {
    deficiency_uid: null,
    status: IPDeficiencyStatus.NoStatus,
    reviewStatus: AIDeficiencyReviewStatus.Incorrect,
    description: null,
    page_no: null
};
