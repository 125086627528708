function luminance(hex) {
    const strippedHex = hex.replace(/^#/, '');
    let r;
    let g;
    let b;
    if (strippedHex.length === 6) {
        r = parseInt(strippedHex.slice(0, 2), 16);
        g = parseInt(strippedHex.slice(2, 4), 16);
        b = parseInt(strippedHex.slice(4, 6), 16);
    }
    else if (strippedHex.length === 3) {
        r = parseInt(strippedHex[0] + strippedHex[0], 16);
        g = parseInt(strippedHex[1] + strippedHex[1], 16);
        b = parseInt(strippedHex[2] + strippedHex[2], 16);
    }
    else {
        console.error(`Invalid color hex provided: ${hex}`);
        return 0;
    }
    return 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);
}
export function blackOrWhiteForeground(background) {
    return luminance(background !== null && background !== void 0 ? background : '#FFF') < 0.85 ? '#FFF' : '#000';
}
export function flattenOpacity(hex, bg = '#ffffff') {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const a = parseInt(hex.slice(7, 9), 16) / 255;
    const br = parseInt(bg.slice(1, 3), 16);
    const bgG = parseInt(bg.slice(3, 5), 16);
    const bb = parseInt(bg.slice(5, 7), 16);
    // Blend each channel
    const blend = (fg, bg) => Math.round(fg * a + bg * (1 - a));
    const finalR = blend(r, br);
    const finalG = blend(g, bgG);
    const finalB = blend(b, bb);
    // Convert back to hex
    return '#' + [finalR, finalG, finalB].map((c) => c.toString(16).padStart(2, '0')).join('');
}
