import React, { useCallback, useEffect, useState } from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import Table from '@components/Table';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import Banner, { BannerVariant } from '@components/Banner';
import LoaderToast from '@components/Loader/LoaderToast';
import { Button, ButtonSize, ButtonVariant } from '@components/Button';
import useDocuments from './hooks/useDocuments';
import AddDocumentModal from './AddDocumentModal';
import { apiInternalInspectionDocumentsPath, apiInternalWorkOrderDocumentsPath, deficienciesAiPath } from '../../routes';
import getDocumentColumns from './utils/getDocumentColumns';
import './DocumentList.scss';
import DeficienciesAi from './components/DeficienciesAI';
const namespace = 'features.documents';
function DocSection({ subcontractor, onRequestDelete, onRequestScan, idLoading, canDelete, onOpenDeficiencies }) {
    const [expanded, setExpanded] = useState(true);
    return (React.createElement("section", { className: `workspace__section documentListContainer ${expanded ? '--expanded  --active' : '--collapsed'}r` },
        React.createElement("button", { type: "button", className: "section__title", onClick: () => setExpanded(!expanded) },
            React.createElement("h2", { className: "section__label" }, subcontractor.name),
            React.createElement("hr", { className: "section__split" })),
        React.createElement("article", { className: "section__content" },
            React.createElement(Table, { columns: getDocumentColumns({
                    onRequestDelete,
                    onRequestScan,
                    idLoading,
                    canDelete,
                    aiEnabled: subcontractor.docs.some((doc) => doc.data.some((d) => d.canAi)),
                    onOpenDeficiencies
                }), data: subcontractor.docs, isGrouped: true }))));
}
function DocumentsList({ isPrime, workOrderId, canDelete, inspectionId, canCreate, canSettings, scanAi, canAi, subcontractors }) {
    const { addModalInfo, groupedDocuments, documentsType, confirmModalInfo, idLoading, isAIProcessing, groupedPrimeDocuments, documentDetail, pendingDocsForReview, onAddDocument, onFinishReviewDeficiencies, onRequestDelete, onRequestScan, onRequestAdd, closeConfirmModal, closeAddModal, setDocumentDetail } = useDocuments({
        subcontractors,
        workOrderId,
        inspectionId,
        isPrime
    });
    const onCloseBrushAway = useCallback(() => {
        setDocumentDetail(null);
    }, []);
    useEffect(() => {
        const alertContainer = document.getElementById('react-alerts-container');
        render(pendingDocsForReview > 0 && isPrime ? (React.createElement(Banner, { text: translate('pending_docs_review', { namespace, count: pendingDocsForReview }), variant: BannerVariant.Info })) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        React.createElement(React.Fragment, null)), alertContainer);
    }, [pendingDocsForReview, isPrime]);
    return (React.createElement(React.Fragment, null,
        React.createElement(LoaderToast, { show: isAIProcessing && isPrime, closable: true }),
        !isPrime &&
            documentsType.map((documentType) => groupedDocuments[documentType.id].length > 0 || canCreate ? (React.createElement("section", { key: documentType.id, className: "workspace__section documentListContainer" },
                React.createElement("header", { className: "section__title" },
                    React.createElement("h2", { className: "section__label" },
                        documentType.title,
                        documentType.is_required && React.createElement("span", { className: "requiredFile" }, " *")),
                    React.createElement("hr", { className: "section__split" })),
                React.createElement("article", { className: "section__content" },
                    groupedDocuments[documentType.id].length > 0 && (React.createElement(Table, { columns: getDocumentColumns({
                            onRequestDelete,
                            idLoading,
                            canDelete,
                            aiEnabled: false
                        }), data: groupedDocuments[documentType.id] })),
                    canCreate && (React.createElement(Button, { onClick: () => onRequestAdd(documentType), variant: ButtonVariant.BlueAccent, size: ButtonSize.Full },
                        React.createElement("i", { className: "fa-light fa-circle-plus" }),
                        translate('add_document', { namespace })))))) : null),
        isPrime && canAi && canSettings && (React.createElement("div", { style: { padding: '0 2.4rem' } },
            scanAi && translate('autorun', { namespace }),
            !scanAi && translate('manual', { namespace }),
            "\u00A0",
            React.createElement("a", { href: deficienciesAiPath(), target: "_blank", rel: "noreferrer" },
                scanAi && translate('scan_ai_link_auto', { namespace }),
                !scanAi && translate('scan_ai_link_manual', { namespace })))),
        isPrime &&
            groupedPrimeDocuments.map((subcontractor, idx) => (React.createElement(DocSection, { key: idx, subcontractor: subcontractor, onRequestDelete: onRequestDelete, onRequestScan: onRequestScan, idLoading: idLoading, canDelete: canDelete, documentType: documentsType[idx], onOpenDeficiencies: setDocumentDetail }))),
        React.createElement(DeficienciesAi, { inspectionId: inspectionId !== null && inspectionId !== void 0 ? inspectionId : 0, document: documentDetail, onClose: onCloseBrushAway, onFinish: onFinishReviewDeficiencies }),
        confirmModalInfo && (React.createElement(ConfirmDialog, { title: confirmModalInfo.title, description: confirmModalInfo.description, onConfirm: confirmModalInfo.onConfirm, onCancel: closeConfirmModal })),
        React.createElement(AddDocumentModal, { isOpen: !!addModalInfo, onAddDocument: onAddDocument, onClose: closeAddModal, documentType: addModalInfo === null || addModalInfo === void 0 ? void 0 : addModalInfo.documentType, saveUrl: inspectionId
                ? apiInternalInspectionDocumentsPath('', {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    inspection_id: inspectionId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    uploader_tenant_id: addModalInfo === null || addModalInfo === void 0 ? void 0 : addModalInfo.subTenantId
                })
                : // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-non-null-assertion
                    apiInternalWorkOrderDocumentsPath('', { work_order_id: workOrderId }) })));
}
export default DocumentsList;
DocumentsList.propTypes = {
    isPrime: PropTypes.bool,
    workOrderId: PropTypes.number,
    inspectionId: PropTypes.number,
    canDelete: PropTypes.bool,
    canCreate: PropTypes.bool
};
DocumentsList.defaultProps = {
    isPrime: false,
    workOrderId: undefined,
    inspectionId: undefined,
    canDelete: false,
    canCreate: false
};
