import React, { useContext, useMemo } from 'react';
import { SwimlaneContext } from './SwimlaneContext';
import { EventContextProvider } from '../Event/EventContext';
import Event from '../Event/Event';
import { RearrangeContext } from '../../Contexts/RearrangeContext';
function Swimlane() {
    var _a, _b;
    const { assignee, events, clusteringData } = useContext(SwimlaneContext);
    const { setTargetAssignee } = useContext(RearrangeContext);
    const laneHeight = useMemo(() => {
        let maxSubdivisions = 1;
        for (const data of Object.values(clusteringData)) {
            if (data.subdivision > maxSubdivisions) {
                maxSubdivisions = data.subdivision;
            }
        }
        return maxSubdivisions * 50;
    }, [clusteringData]);
    const swimlaneEvents = useMemo(() => {
        return events.map((event) => {
            return (React.createElement(EventContextProvider, { key: event.key, event: event, clusterInfo: clusteringData[event.key] },
                React.createElement(Event, null)));
        });
    }, [events, clusteringData]);
    const handleMouseEnter = () => {
        setTargetAssignee(assignee);
    };
    return (React.createElement("div", { id: `swimlane-${assignee.type}-${(_b = (_a = assignee.info) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 'unassigned'}`, className: "event__lane", style: { minHeight: `${laneHeight}px` }, onMouseEnter: handleMouseEnter }, swimlaneEvents));
}
export default Swimlane;
