import React from 'react';
import { BrushawayProvider } from './BrushawayContext';
import Brushaway from './Brushaway';
import I18n from '../../utils/i18n';
function BrushawayWrapper({ children, anchor, title, isForm, onSubmit, submitButtonText, minWidth, open, onClose, wrapperContentClassName, footerClassName, disableCloseOnClickOutside, customFooter, isValid }) {
    return (React.createElement(BrushawayProvider, { controlledOpen: open, onClose: onClose },
        React.createElement(Brushaway, { anchor: anchor, title: title, isForm: isForm, onSubmit: onSubmit, submitButtonText: submitButtonText, wrapperContentClassName: wrapperContentClassName, footerClassName: footerClassName, minWidth: minWidth, customFooter: customFooter, isValid: !!isValid }, children)));
}
BrushawayWrapper.defaultProps = {
    anchor: undefined,
    isForm: false,
    onSubmit: undefined,
    submitButtonText: I18n.t('components.brushaway.save'),
    disableCloseOnClickOutside: false,
    open: undefined,
    onClose: undefined,
    minWidth: 400,
    customFooter: undefined,
    wrapperContentClassName: '',
    footerClassName: '',
    isValid: true
};
export default BrushawayWrapper;
