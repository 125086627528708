import React, { useContext } from 'react';
import { SignaturesListContext } from './SignaturesListContext';
import { Loader, LoaderSizes } from '../../components/Loader/Loader';
import { SignatureFormContext } from './SignatureFormContext';
function SignatureTypesPopup(params) {
    const { signatureTypes, signatureTypesLoading, openNewSignature } = useContext(SignatureFormContext);
    const { defaultTechnicianId } = useContext(SignaturesListContext);
    return (React.createElement("div", { className: "qmb-popup--actions--y-max" },
        React.createElement("div", { className: "popup__section--y-fill" }, signatureTypesLoading ? (React.createElement("div", { style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px',
                paddingTop: '20px'
            } },
            React.createElement(Loader, { size: LoaderSizes.medium }))) : (React.createElement("ul", { role: "presentation" }, signatureTypes
            .filter((x) => x.blurb !== null || x.key === 'inspector')
            .map((type) => (React.createElement("li", { key: `signature-type-option-${type.key}` },
            React.createElement("button", { type: "button", className: "qmb-control", onClick: () => {
                    if (type.key === 'inspector') {
                        openNewSignature(type, defaultTechnicianId);
                    }
                    else {
                        openNewSignature(type, null);
                    }
                    params.onSelect();
                } }, type.label)))))))));
}
export default SignatureTypesPopup;
