/* eslint-disable react/require-default-props */
import React, { useMemo } from 'react';
import { translate } from '@utils/i18n';
import FullPageModal from '@components/Modal/FullPageModal';
import { Button, ButtonVariant } from '@components/Button';
import LoadableButton from '@components/Button/LoadableButton';
import ConfirmDialog from '@components/ConfirmDialog/ConfirmDialog';
import PDFSection from './PDFSection';
import { namespace } from '../constants';
import DeficienciesListSection from './DeficienciesListSection';
import useDeficienciesAI from '../hooks/useDeficienciesAI';
import DeficiencyDetail from './DeficiencyDetail';
import FeedbackModal from './FeedbackModal';
import generateDefTitle from '../utils/generateDefTitle';
export default function DeficienciesAi({ document, inspectionId, onClose, onFinish }) {
    const { pdfViewerStatus, deficiencyDetail, defIdLoading, requestAddToInspectionModalOpen, bulkUpdateLoading, isEmpty, highlightInfo, onAddFeedback, onAddToInspection, onBulkUpdate, setRequestAddToInspectionModalOpen, onChangeDeficiency, onFinishReview, setPdfViewerStatus, setDeficiencyDetail, onStartHighlightText, onStopHighlightText, isCompletingReview, deficiencies, pendingDeficiencies, openFeedbackModal, setOpenFeedbackModal, setOpenNewDeficiency } = useDeficienciesAI({
        inspectionId: inspectionId !== null && inspectionId !== void 0 ? inspectionId : 0,
        parentDocument: document,
        onFinish
    });
    const detailTitle = useMemo(() => {
        return generateDefTitle(deficiencyDetail, deficiencies);
    }, [deficiencyDetail, deficiencies]);
    return (React.createElement(FullPageModal, { title: translate('review_deficiencies', { namespace }), isOpen: !!document, onClose: onClose, footerText: pendingDeficiencies > 0 ? (React.createElement("span", null,
            React.createElement("b", null, translate('pending_review', { namespace, count: pendingDeficiencies })),
            ' ',
            translate('needs_to_be_corrected', { namespace }))) : null, actionButtons: [
            React.createElement(Button, { key: "closeButton", onClick: onClose }, translate('close', { namespace: 'features.commons' })),
            React.createElement(Button, { key: "feedbackButton", variant: ButtonVariant.Outline, onClick: () => setOpenFeedbackModal(true) }, translate('how_did_we_do', { namespace })),
            isEmpty ? (React.createElement(LoadableButton, { loading: isCompletingReview, key: "completeButton", variant: ButtonVariant.PurpleHighlight, onClick: () => onFinishReview() }, translate('review_complete', { namespace }))) : (React.createElement(Button, { disabled: pendingDeficiencies > 0, key: "addButton", variant: ButtonVariant.PurpleHighlight, onClick: () => setRequestAddToInspectionModalOpen(true) }, translate('add_to_inspection', { namespace })))
        ].filter(Boolean) },
        React.createElement("div", { className: "deficienciesAIGeneral" },
            React.createElement(PDFSection, { onStartSelecting: onStartHighlightText, onStopSelecting: onStopHighlightText, pdfViewerStatus: pdfViewerStatus, document: document, onChangeViewerStatus: setPdfViewerStatus }),
            React.createElement(DeficienciesListSection, { isEmpty: isEmpty, highlightInfo: highlightInfo, onBulkUpdate: onBulkUpdate, bulkUpdateLoading: bulkUpdateLoading, onOpenDeficiency: (def) => {
                    setDeficiencyDetail(def);
                    onStopHighlightText();
                }, pdfViewerStatus: pdfViewerStatus, deficiencies: deficiencies, defIdLoading: defIdLoading })),
        React.createElement(DeficiencyDetail, { title: detailTitle, deficiency: deficiencyDetail, onClose: () => {
                setOpenNewDeficiency(false);
                setDeficiencyDetail(null);
            }, onChangeDeficiency: onChangeDeficiency }),
        requestAddToInspectionModalOpen && (React.createElement(ConfirmDialog, { title: translate('add_defs_to_inspection', { namespace }), description: translate('add_confirmation', { namespace }), onConfirm: () => {
                void onAddToInspection();
            }, onCancel: () => setRequestAddToInspectionModalOpen(false), yesLabel: translate('add_to_inspection', { namespace }) })),
        React.createElement(FeedbackModal, { isLoading: isCompletingReview, isOpen: openFeedbackModal, onClose: () => setOpenFeedbackModal(false), onSubmit: (text) => {
                void onAddFeedback(text);
            } })));
}
